.chats-bg-container{
    margin-left: 210px;
    
}
.chatlist-container{
    min-height:90vh;
    width:30%;
    border:1px solid gray;
    padding-top:14px;
    overflow-y: auto;
    max-width: 100%;
}
.chatlist-container1{
    height:95vh;
    overflow-y: auto;
    border:1px solid #D3D3D3;
    background-color: #f5f5f5;
    border-radius: 10px;
    margin-top:20px;
    width:60%;
    margin-left: 10px;

}
.chat-card-container{
    height:70px;
    width:95%;
    margin-left:10px;
    background-color: #ffffff;
    display: flex;
    border: 1px solid #D3D3D3;
    margin-top: 10px;
    border-radius: 5px;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    
}
.profile-image-chats{
    height: 55px;
    width:55px;
    border-radius: 50%;
    padding:8px;
  
}
.chat-list-div{
    width: 350px;
    height:95vh;
    overflow-y: auto;
    border:1px solid #D3D3D3;
    background-color: #f5f5f5;
    border-radius: 10px;
    margin-top:20px;
}
@media only screen and (max-width: 768px) {
    .chats-bg-container{
        margin-left: 0px;
    }
    .chatlist-container{
        min-height:90vh;
       
        width: 100%;
    }
   
}
.chat-profile-div{
    height:65px;
    display: flex;
    align-items: center;
    border-bottom:1px solid #D3D3D3;
    padding-left: 10px;
    background-color: #ffffff;
    cursor: pointer;

}
.profile-image-chats1{
    height: 35px;
    width:35px;
    border-radius: 50%;
}
.messages-container3{
    height:70vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 10px;
    overflow-y: auto;
}
.sending-messages{
    font-family: roboto;
    font-size:14px;
    color:#2e2e2e;
    align-self:flex-end;
    text-align: left;
    background-color: #0d92f9;
    padding:7px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    color:#ffffff;
    margin-left: 50px;
}
.receiving-messages{
    font-family: roboto;
    font-size:14px;
    color:#2e2e2e;
    align-self:flex-start;
    text-align: left;
    background-color:#ffffff;
    padding:7px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    color:#81818b;
    margin-right: 50px;
}
.chat-date{
    font-family: roboto;
    font-size:10px;
    color:black;
    font-weight: 600;
    align-self:center;
    text-align: left;
    background-color: #e8e8ea;
    padding:3px;
    border-radius: 3px;
}
.chat-input-container{
    display: flex;
    align-items: center;
    width:100%;
}
.message-input{
    height:40px;
    width:80%;
    border-radius: 10px;
    margin-top: 10px;
}
.msg-send-btn{
    height:35px;
    width:50px;
    border-radius: 8px;
    background-color: #0d92f9;
    border-width: 0px;
    color:#ffffff;
    margin-top:10px;
    margin-left: 10px;

}
.chat-date-text{
   
    justify-content:flex-end;
}
.active-text{
    font-family: roboto;
    font-size:15px;
    color:#2e2e2e;
    background-color: #0d92f9;
}