*{
    box-sizing: border-box;
}
.forgot-password-bg-container{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.forgot-password-container{
    width:500px;
    max-width: 100%;
    margin-top: 15px;
    border: 1px solid gray;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:90vh;
   
}
.profile-icon-div{
    height:100px;
    width:100px;
    border-radius: 50%;
    border: 1px solid gray;
    margin:20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-icon{
    font-size:30px;
    font-weight:bold;
}
.trouble-text{
    font-size:20px;
    font-weight:bold;
}
.information-text{
    font-size:14px;
    font-family:roboto;
    font-weight:normal;
    color:#2e2e2e;
    text-align: center;
    margin-top:17px;
}
.information-text-1{
    font-size:14px;
    font-family:roboto;
    font-weight:bold;
    color:#2e2e2e;
    text-align: center;
    margin-top:17px;
}
.username-input-filed {
    font-size: 14px;
    height: 30px;
    border: 1px solid #d7dfe9;
    background-color: #e2e8f0;
    color: #64748b;
    border-radius: 2px;
    margin-top: 5px;
    padding: 8px 16px 8px 16px;
  }
  .login-button{
    font-family: 'Roboto';
    font-weight: normalbold;
    font-size: 14px;
    color: #ffffff;
    height: 35px;
    width: 140px;
    margin-top: 20px;
    background-color: #b18165;
    border-radius: 8px;
    border: none;
  }
  .bottom-login-div{
    height:55px;
    border-top: 1px solid gray;
    margin-top: auto;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo-image-signup{
    height: 90px;
  }
  .edit-profile{
    font-family: roboto;
    font-size:15px;
    font-weight:bold;
  }
  .old-password{
    font-family:roboto;
    font-size:14px;
    margin:0px;
    padding:0px;
  }
  .edit-profile-image{
    height: 100px;
    width:100px;
    border-radius: 50%;
  }

  .custom1-file-input {
    font-size: 1.2rem;
    color: #fff;
    background-color: #b18165;
    border: 2px solid #555;
    border-radius: 5px;
    padding: 10px;
    outline: none;
    transition: all 0.3s ease;
  }
  
  .custom1-file-input:hover {
    background-color: #555;
  }
  
  .custom1-file-input:focus {
    border-color: #f00;
  }