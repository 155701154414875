.aframe-div{
    height:360px;
    width:720px;
}
.thumbnail-container{
    height:75px;
    width:150px;
    border-radius: 5px;
    background-color: aqua;
}
#map-imagess{
  width:200px;
   /* margin-left: 17%;
   margin-bottom: 30px; */
   transform: translate(25%, -120%);
   opacity:0.5; 
}
.thumbnail-image{
    width: 100%;
    height: 100px;
}
.scene-row-div{
    display: flex;
    flex-direction: row;
}
.scene-container{
  height:75vh;
  width: 65vw;
}

.scene-row-container{
    display: flex;
    flex-direction: row;
  }
  .create-scene-row-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

  }
  .thumbnail-div-2{
   
    width:150px;
    border: 1px solid gray ;
    margin-top: 5px;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
  }
  .thumbnail-image-2{
    width:100%;
    height:70px;
    
  }
  .scene-name-div-1{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .scene-name1{
    font-family: roboto;
    font-size: 14px;
    font-weight: normal;
    color:#2e2e2e;
    cursor: pointer;
  }
  .scene-row-divv{
    display: flex;
    flex-direction: row;
  }
  .thumb-row-div{
    display: flex;
    flex-direction: row;
  }
  .hotspots-lists{
  

    background-color: whitesmoke;
    border:1px solid gray;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 5px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .scenes-lists{
  
    width:180px;
    background-color: whitesmoke;
    border:1px solid gray;
    margin-left: 5px;
    border-radius: 5px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    padding-left: 7px;
    height:70vh;
    overflow-y: auto;
  
  }
  .map-lists{
  
    
    background-color: whitesmoke;
    border:1px solid gray;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .hotspots-heading{
    font-family: roboto;
    font-size:14px;
    font-weight: bold;

  }
  .hello{
    border:1px solid #ef9379;
    border-radius: 5px;
  }
  .tick-icon{
    font-size: 20px;
    cursor: pointer;
    margin-top: 10px;
    
  }
  .onselect-tick{
    color:green
  }
  select{
    border-radius: 5px;
    height: 28px;
  }
  .hotspot-row-div{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .upload-image1{
    width:200px;

  }
  .spinner{
    position: 'absolute';
    left: 50%;
   top: 16%;
    
    
  }
  .hotspot-name13{
    font-size:14px;
    margin-top: 12px;
  }
  .hello1{
    display: none;
  } 

  label[for="file-upload1"] {
    background-color: #2e2a2b;
    padding-top: 3px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    width:85%;
    height:29px;
  }

  label[for="file-upload"]:hover {
    background-color: #2e2a2b
    ;
  }
  
  label[for="file-upload2"] {
    background-color: #2e2a2b;
    padding-top: 6px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    width:95%;
    height:39px;
  }

  label[for="file-upload2"]:hover {
    background-color: #2e2a2b
    ;
  }
  
  label[for="file-upload6"] {
    background-color: #2e2a2b;
    padding-top: 0px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  
    height:29px;
 
    width:100px;
  }

  label[for="file-upload6"]:hover {
    background-color: #2e2a2b
    ;
  }
  
  

  label[for="file-upload6"]:hover {
    background-color: #2e2a2b
    ;
  }
  
  
  label[for="file-upload6"]:hover {
    background-color: #2e2a2b
    ;
  }
  .create-scene-button{
    font-family: 'Roboto';
    font-weight: normalbold;
    font-size: 14px;
    color: #ffffff;
    height: 35px;
    width: 140px;
    background-color: #2e2a2b;
    border-radius: 8px;
    border: none;
  }
  #container23 { display:block; }
  @media only screen and (orientation:portrait){
    #container23 {  
      height: 100vw;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
  @media only screen and (orientation:landscape){
    #container23 {  
       -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
       -o-transform: rotate(0deg);
       -ms-transform: rotate(0deg);
       transform: rotate(0deg);
    }
  }

  @media only screen and (max-device-width: 1000px) 
                     and (orientation: landscape) {
                      .create-scene-button{
                        font-family: 'Roboto';
                        font-weight: normalbold;
                        font-size: 12px;
                        color: #ffffff;
                        height: 25px;
                        width: 80px;
                        background-color:#2e2a2b;
                        border-radius: 5px;
                        border: none;
                      }
                      .scenes-lists{
  
                        width:160px;
                        background-color: whitesmoke;
                        border:1px solid gray;
                        margin-left: 0px;
                        border-radius: 5px;
                        text-align: center;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        box-sizing: border-box;
                        padding-left: 0px;
                      
                      }
                      .map-lists{
  
    
                        background-color: whitesmoke;
                        border:1px solid gray;
                        margin-left: 3px;
                        margin-right: 3px;
                        border-radius: 5px;
                        text-align: center;
                        margin-bottom: 10px;
                        padding-bottom: 10px;
                        padding-top: 0px;
                      }
                      .hotspots-lists{
  
                      
                        background-color: whitesmoke;
                        border:1px solid gray;
                        margin-left: 3px;
                        margin-right: 3px;
                        border-radius: 5px;
                        text-align: center;
                        padding-top: 10px;
                        padding-bottom: 10px;
                      }
 
  }
  @media only screen and (max-width: 769px) and (orientation: landscape) {
  
    .hotspots-lists{
  
      
      background-color: whitesmoke;
      border:1px solid gray;
      margin-left: 3px;
      margin-right: 3px;
      border-radius: 5px;
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }

  #map-imagess{
    width:100px;
     /* margin-left: 17%;
     margin-bottom: 30px; */
     transform: translate(5%, -120%);
    
  }
  .thumbnail-div-2{
   
    width:100px;
    border: 1px solid gray ;
    margin: auto;
    border-radius: 5px;
  }
  .thumbnail-image-2{
    width:100%;
    height:50px;
    
  }
  .scene-name1{
    font-family: roboto;
    font-size: 12px;
    font-weight: normal;
    color:#2e2e2e;
    cursor: pointer;
  }
  .hotspot-name13{
    font-size:12px;
  }
  .dropdown-option{
    width:32px;
    height:30px;
    font-size:12px;
  }
}