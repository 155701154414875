.vr-container-details{
    width: 100vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height:100vh;
    margin-top: 20px;
  }
  
  .main-heading{
      font-family: roboto;
      font-size:20px;
      font-weight:bold;
  
  }
  .body-vr-container{
      text-align: center;
      margin-top:20px;
      width:90%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
  }
  .title-row-div{
      display: flex;
      align-items: center;
      justify-content: space-around;
  }
  .title-text{
      font-family: roboto;
      font-size:18px;
      font-weight:normal;
      margin-right: 20px;
  }
  .virtual-row-div{
      display:flex;
      flex-direction:row;
  
  }
  .thumbnail-div{
      width:200px;
      height:160px;
      border:1px gray solid;
      border-radius: 5px;
      margin-left: 10px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      padding-bottom: 5px;
      margin-top: 14px;
  }




  .vr-container{
    width: 100%;
   
   
}
.vr-container-details{
  width: 60%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height:100vh;
  margin-left: 260px;
  margin-top: 15px;
}
.main-heading{
    font-family: roboto;
    font-size:20px;
    font-weight:bold;

}
.body-vr-container{
    text-align: center;
    margin-top:20px;
    width:50%;
    margin-left: auto;
    margin-right: auto;
}
.title-row-div{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.title-text{
    font-family: roboto;
    font-size:18px;
    font-weight:normal;
    margin-right: 20px;
}
.virtual-row-div{
    display:flex;
    flex-direction:row;

}
.thumbnail-div{
    width:200px;
    height:160px;
    border:1px gray solid;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 5px;
    margin-top: 14px;
}
.hello{
    display: none;
  } 

  label[for="file-upload"] {
    background-color: #0275d8;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    width:100%;
    height:30px;
  }

  label[for="file-upload"]:hover {
    background-color: #0275d8
    ;
  }
  .edit-and-delete-div{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width:100%;
  }
  .thumbnail-image-1{
    width:100%;
    height:70px;
    
  }
  .inside-thimb-columb-div{
    display: flex;
    flex-direction: column;
    height:90vh;
    overflow-y: auto;
  }
  .preview-image{
    height:450px;
    width:80%;
    margin-left:auto;
    margin-right: auto;
    margin-top:10px;

  }
  .sceneimage{
    height:450px;
    width:80%;
  }
  .scene-name-div-1{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .scene-name{
    font-family: roboto;
    font-size: 14px;
    font-weight: normal;
    color:#2e2e2e;
  }
  .thumb-colum-divv{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: auto;
    height:90vh;
  }
  .virtual-toure-view{
    width:80%;
    height:80vh
  }
  .outer-thumb-divv{
    display: flex;
    flex-direction: row;
    height:90vh;
  }
  .scene-row-div-1{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .thumbnail-containers-1{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height:90vh;
    overflow: auto;
  }
  .thumbnail-div-1{
   
    width:160px;
    border: 1px solid gray ;
    margin: 5px;
    border-radius: 5px;
  }
  .thumb-360-image{
    width:300px;
    border-radius:5px;
    margin-left: auto;
    margin-right: auto;

  }
  .active-tab{
    border: 1px solid red;
  }
  .hotspots-list{
    height:60vh;
    width:200px;
    background-color: whitesmoke;
    border:1px solid gray;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 5px;
    text-align: center;
  }
  .hotspots-heading{
    font-family: roboto;
    font-size:14px;
    font-weight: bold;

  }
  .tour-container{
    margin-left: 220px;
    width:70%;
    min-height: 100vh;
    align-items: left;
    
  }
  .icon-style{
    font-size:16px;
    font-weight: bold;
  }
  .icon-style1{
    font-size:16px;
    font-weight: bold;
  }
  .virtual-tour-image-size{
    height:30vh;
   
  }
  @media only screen and (max-width: 768px) {
    .tour-container{
      margin-top:45px;
      margin-left: 0px;
      width:98%;
      height:80vh;
      overflow-y: auto;
      padding-bottom: 50px;
      
    }
    .scene-row-div-1{
     
      flex-direction: column;
      
    }
    .thumbnail-div-1{
     
      min-width:150px !important;
      border: 1px solid gray ;
      margin: 5px;
      border-radius: 5px;
    }
    .thumbnail-containers-1{
      min-height: 150px;
     width:100vw;
    flex-direction: row;
    height: 100px;
    overflow-x: auto;
     
    }
    
    .thumbnail-image-1{
      width:100%;
      height:70px;
      
    }
    .main-heading{
      margin-top: 55px;
  }
    .virtual-toure-view{
      width:100%;
     
    
    }
    .body-vr-container{
       height:100vh;
        width:100%;
        
    }
    .vr-container-details{
      width: 80%;
      margin-left: 20px;
      margin-bottom:300px;
    }
    .thumb-colum-divv{
      
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      overflow: auto;
      height:100px;
      width:100vw;
    }
    .virtual-row-div{
        display:flex;
        flex-direction:column;
    
    }
    .inside-thimb-columb-div{
        display: flex;
        flex-direction: row;
        height:200px;
        overflow-x: auto;
        align-items: flex-start;
        
    }
    .preview-image{
        height:450px;
        width:100%;
        margin-left:auto;
        margin-right: auto;
        margin-top:0px;
    
      }
    
      .outer-thumb-divv{
       
        flex-direction: column;
        height:100vh;
      }
    }