@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Caveat:wght@400;700&family=Lobster&family=Monoton&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display+SC:ital,wght@0,400;0,700;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,700&display=swap");
*{
  box-sizing: border-box;
}
.menu-button{
    height:40px;
    width: 100px;
    background-color:#1172c2;
    border-radius: 10px;
    color: #ffffff;
    border-width:0px;
}
.create-scene-button1{

  font-family: 'Roboto';
  font-weight: normalbold;
  font-size: 14px;
  color: #ffffff;
  height: 35px;
  width: 100px;
  background-color: #2e2a2b;
  border-radius: 8px;
  border: none;
}

.hello1{
  display: none;
} 

label[for="file-upload1"] {
  background-color: #2e2a2b;
  padding-top: 3px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  width:130px;
  height:29px;
}

label[for="file-upload"]:hover {
  background-color: #2e2a2b
  ;
}
label[for="file-upload9"] {
  background-color: #2e2a2b;
  padding-top: 3px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  width:100%;
  height:29px;
}

label[for="file-upload9"]:hover {
  background-color: #2e2a2b
  ;
}

label[for="file-upload7"] {
  background-color: #2e2a2b;
  padding-top: 3px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  width:100%;
  height:29px;
}

label[for="file-upload7"]:hover {
  background-color: #2e2a2b
  ;
}
.uploadPost{
    font-family:roboto;
    font-size:14px;
    font-weight:bold;
}
.post-image-size{
  height:96%;
  width:100%;
}
.post-div-size{
 
width:45vw;;
  overflow-y: auto;
  margin-bottom: 10px;
}
.post1-div-size{
 
  width:45vw;;
    overflow-y: auto;
    margin-bottom: 10px;
    height: 45vh;
  }
.formDiv1{
    display: flex;
    flex-direction:column;
    align-items: center;
    min-width:80vw;
    background-color:whitesmoke;
    padding:20px;
    margin-left: 150px;
    height:100vh;
    box-sizing: border-box;
    overflow-y: hidden;
}
.thumb-nails{
  height:60px;
  width: 60px;
  margin-left: 5px;
  border-radius: 7px;
  margin-bottom: 5px;
  object-fit: cover;
}
.postForm1{
    display: flex;
    flex-direction:column;
    align-items: left;
    text-align: left;
    box-shadow: 2px 2px 2px 2px lightblue;
    width:55vw;
    margin-left: auto;
    margin-right: auto;
    padding:30px;
    background-color:#ffffff;
    height:100vh;
    overflow-y: auto;
    margin-left: 300px;
    
  
}
.suggitions-div{
  display: flex;
  flex-direction: row;
  max-width:45vw;
  flex-wrap: wrap;
  height:20vh;
  overflow-y: auto;
  
}
.suggition-card-div{
  height: 50px;
  background-color: blue;
  border-radius: 5px;
  text-align: center;
  min-width: none;
}

.hash-tag {
  margin-top: 3px;
  outline: 0;
  border-width: 0 0 2px;
  border-color: #2e2a2b;
  width:100%;
}
.setting-icon{
  font-size:20px;
  color:#6772a8;
}
.video-player{
  width:100%;
  height: 96%;
  object-fit: cover;
  position: 'absolute'
}

.hash-tag:focus {
  border-color: #b18165;
 
}
.postTitle{
    font-family:roboto;
    font-size:14px;
    font-weight:normal;
    margin-bottom:2px;
    margin-top: 5px;
}
.rowButtonDiv{
    display:flex;
    margin-left: 35%;
}
.post-button{
    height:40px;
    width: 100px;
    background-color:#1172c2;
    border-radius: 10px;
    color: #ffffff;
    border-width:0px;
    margin-top: 20px;
}
.options{
    margin-top:20px;
    margin-left:20px;
}
.create-post-div{
    display: flex;
    
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-top: 10px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .comment-turnoff-text{
    font-family: roboto;
    font-size: 12px;
    color:#2e2e2e;
  }
  .post-image{
    width:80%;
  }
  
  .form-scrool-div{
   
    overflow-y: auto;
    box-sizing: border-box;
    margin-bottom: 50px;
  }
@media screen and (max-width: 600px) {
  
  .suggitions-div{
    max-width:100%;
    overflow-y: auto;
  }
    .formDiv1{
   
        display: flex;
        flex-direction:column;
        align-items: center;
        min-width:60vw;
        margin-left: 0px;
        height:100vh;
        overflow-y: hidden;
        
    }
    .postForm1{
     
      width:100vw;
      margin-left: 0px;
      margin-right: 0px;
      padding:0px;
      height:92vh;
      background-color:#ffffff;
      box-sizing: border-box;
      margin-top:55px;
      
    
  }
  .post-div-size{
    height:200px;
  width:100%;;
    overflow-y: auto;
  }
  .post1-div-size{
 
    height:200px;
    width:100%;;
      overflow-y: auto;
    }
  .form-scrool-div{
  height:40vh;
    overflow-y: auto;
    box-sizing: border-box;
    margin-bottom: 40px;
  }

  }