*{
    box-sizing: border-box;
}
.story-image{
    height:70px;
    width:70px;
    border-radius: 50%;
    margin-right:10px;
    
}
.story-div{
    overflow-x: auto;
}
.feed-container{
    width:40%;
    margin-left:330px;
    background-color: #ffffff;
    margin-right:20px ;
   
}
.profile-row-container{
    display: flex;
    flex-direction:row;
}
.profile-image1{
    height: 55px;
    width:55px;
    border-radius: 50%;
    padding:8px;
    margin-left: 0px;
}
.comment-profile-image{
    height: 50px;
    width:50px;
    border-radius: 50%;
    padding:8px;
    margin-left: 0px;
}
.profile-name1{
    font-family:"roboto";
    font-size:15px;
    margin-top: 7px;
  
}
.comment-text{
    font-family: "Roboto";
    font-size: 13px;
    font-weight: normal;
}
.comment-time-text{
    font-family: "roboto";
    font-size: 13px;
    font-weight: normal;
    
}
.time-text{
    font-family: "roboto";
    font-size:11px;
    margin-top:-10px;
}
.profile-name-city{
    font-family:roboto;
    font-size:15px;
    margin-top:0px;
    padding:0px;
}
.profile-name-comments{
    font-family:roboto;
    font-size:15px;
    font-weight: bold;
    margin-top: 14px;
    padding-right:5px;
}
.more-icon1{
   
    font-size:25px;
    margin-left: 25px;
}
.designer-time-column-div{
    display: flex;
    flex-direction: column;
}
.designer-like-column-div{
    display: flex;
    flex-direction: column;
    height:50px;
}
.comments-text{
    font-size: 13px;
    margin: -10px;
    font-family: roboto;
}
.feed-image{
    width:100%;
    height:340px;   
}
.virtual-feed{
    width:100%;
    height:340px;   
    cursor: pointer;
}
.feed-video{
    width:100%;
    height:340px;
  
    object-fit: cover;
}
.likes-text{
    font-family: roboto;
    font-size: 14px;
    margin-left:50%;
    margin-top:-20px;
}
.like-symbol-div{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.like-symbol{
    display: flex;

}
.like1{
    margin-left:25px;
    font-size: 20px;
}
.liked{
    font-size: 20px;
    color:red;
}
.comment{
    margin-left:15px;
    font-size: 25px;
}
.save-share-symbol{
    display: flex;
    margin-right:30px;
}
.on-comment{
    margin-left:15px;
    padding-bottom: 0px;
}
.comment-profile{
    font-family: roboto;
    font-size:14px;
    font-weight:bold;

}
.comment-sapn{
    font-family: roboto;
    font-size:14px;
    font-weight:normal;
    margin-left: -10px;
}
.suggition-container{
    width:20vw;
    height:100vh;
    background-color: #ffffff;
   
    padding:14px;
}
.suggestion-text-container{
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: flex-start;
}
.navbar-container{
    height:55px;
    
    background-color:#ffffff;
    position:fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
}
.bottom-navbar11{
    height:55px;
    background-color:#ffffff;
    position:fixed;
    margin-left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80vw;
   

   
}
.dashbord-image{
    height:85px;
    width:60px;
  
   
    /* border:solid 3px #b18165;
    border-radius: 50%; */
    /* background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    
}
.dashbord-image1{
    height:30px;
    width:30px;
  
   
    /* border:solid 3px #b18165;
    border-radius: 50%; */
    /* background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    
}
#home{
outline: none;
}
#upper {
    position: fixed;
    top: 0;
    width:99vw;
    margin: 0px;
   
   
}
.navbar-logo1{
    height:60%;
    width:100px;
    margin-left: 5px;
}
.notifications-icon2{
    font-size:25px;
}
.notifications-icons{
    font-size:29px;
}
.plus-button{
    color:#b18165;
    font-size: 30px;
}
.emty-div{
    margin-top:55px;
}
.suggition-name{
    font-family: roboto;
    font-size:15px;
    font-weight:normal;
    color:#8f97bf;

}
.see-all{
    font-family: roboto;
    font-size:14px;
    font-weight:bold;
    color:#2e2e2e;


}
.suggestion-profile-container{
    display:flex;
    flex-direction:row;
    justify-content: space-between;

}
.suggestion-profile{
    display:flex;
    flex-direction:row;
    justify-content: space-between;
  
    padding: 0px;
    
}
.profile-name-designation{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height:50px;
  
}
.suggestion-profile-image{
    height: 55px;
    width:55px;
    border-radius: 50%;
    padding:8px;
    margin-left: 0px;
}
.suggestion-profile-name{
    font-size: 14px;
    font-family: roboto;
    font-weight: bold;
    margin-top: 10px;
}
.suggestion-profile-desigenaton{
    font-size: 11px;
    font-family: roboto;
    font-weight: normal;
    margin-top: -10px;
}
.follow-text{
    font-size: 14px;
    font-family: roboto;
    font-weight: bold;
    margin-top: 10px;
    color:#0275d8;
}
.feed-model-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.model-report-text{
    font-family: roboto;
    font-size:14px;
    color:#ed4956;
    font-weight:bold;
    text-align: center;
    margin-top:5px;
}
.model-report-text1{
    font-family: roboto;
    font-size:14px;
    color:black;
    font-weight:bold;
    text-align: center;
    margin-top:5px;
}
.model-text-div{
    border-bottom: 1px solid gray;
    width:100%;
    margin:0px;
  
}
.model-text-div1{
    border-bottom: 1px solid gray;
    width:100%;
   cursor: pointer;
    margin-bottom:10px;
  
}
.descktop-comment-image{
    height:70vh;
    width:100%;
    object-fit: cover;
}
.comment-body{
    display:flex;
}
.comment-section{
    display: flex;
    flex-direction: column;
    width:100%;
}
.comment-section-mobile-view{
    display: flex;
    flex-direction: column;
    width:100%;
}
.comment-header{
    width:100%;
    height:50px;
    border-bottom: 1px solid gray;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.commenting-container{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height:55vh;
    overflow-y: auto;
    
    
}
.commenting-container-mobile-view{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height:70vh;
    overflow-y: auto;
}
.comments-profiles{
    display:flex;
    flex-direction: row;
   width: 100%;
    align-items: flex-start;
   
   border-bottom: 1px solid gray;
    
}

.nav-link {
    text-decoration: none;
    color: black;
   
  }
  .post-count-div{
    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    background-color:black;
    height:30px;
    width:50px;
    border-radius: 6px;
    text-align: center;
    align-items: center;
  }
  .post-count{
    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    COLOR:#ffffff;
    
  }
  .desktop-comment{
    margin-top: 20px;
  }
@media only screen and (min-width: 769px) {
    .navbar-container{
        display:none;
    }
    .emty-div{
        margin-top:0px;
    }
    .bottom-navbar11{
       display: none;
    
       
    }   
    .mobile-view-comment{
        display:none;
    } 
    #footer {
       display: none;
       
       
    }
}
@media only screen and (max-width: 769px) {
    .feed-container-div{
        margin-top: 55px;
       
    }
    #bottom-nav-dashboard{
        margin-bottom: 30px;
        outline: none;
    }
    #footer {
    position: fixed;
    bottom: 0;
    width:99vw;
    margin: 0px;
   
   
}
    
    .home-bg-container{
        margin-left: 0px;
        width:100%;
       
    }
    /* .desktop-comment{
        display:none;
    } */

    .feed-container{
      
        margin-left:0px;
        width:99%;
        margin-right:0px ;
        margin-bottom: 55px;
       
    }
    .profile-image1{
        height: 45px;
        width:45px;
        border-radius: 50%;
        padding:8px;
        margin-left: 0px;
    }
    .suggition-container{
       display:none;
    
    }
   
}
/* @media only screen and (max-width: 650px) {
    .dashbord-image{
        height:20px;
        width:20px;
        outline: none;

    }
    #footer {
        position: fixed;
        bottom: 0;
        width:70vw;
        margin: 0px;
       
       
       
    }
} */
